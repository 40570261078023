import { useState } from "react";
import {ReactComponent as CloseIcon} from '../assets/close.svg';
import {ReactComponent as FullScreenIcon} from '../assets/fullscreen.svg';
import {ReactComponent as FacebookIcon} from '../assets/facebook.svg';
import {ReactComponent as TwitterIcon} from '../assets/twitter.svg';

import { Tween } from "react-gsap";
// import { useNavigate } from "react-router-dom";
import { openFullscreen } from './Model';

export default function Nav() {

    // const btn = document.querySelector('#share');
    // const [setActive] = useState(false);
    const [ showDefaultShare, setShowDefaultShare ] = useState(false);
    const [ seen, setIsSeen ] = useState(0);
    // const [ collectiveCreditsActive, setCollectiveCreditsActive ] = useState(false);
    // const navigate = useNavigate();

    let tweenprops;
    if (showDefaultShare===false) tweenprops = {opacity:1};

    let iclass = 'shareIcon';
    let sclass = 'default-share';
    if (showDefaultShare===true) iclass += ' up';
    if (showDefaultShare===true) sclass += ' active';
    
    function handleShareClick(event) {
        console.log('share click');
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // const response = await fetch(event.target.getAttribute('data-share-image'));
            // const blob = await response.blob();
        
            // const image = new File([blob], 'image.jpg', { type: "image/jpeg" });
            
            function getCurrentURL() {
            return window.location.href;
            }
            const shareLink = getCurrentURL();

            const shareData = {
                    text: "Autolysis",
                    url: shareLink,
            }
            navigator.share(shareData);
            console.log('share enabled');

        }
        else{
                console.log('share not enabled');
                setShowDefaultShare( !showDefaultShare );
        } 
        setIsSeen(1);
    }
    function handleBackClick(){
        window.location.href = 'https://playreplay.ca';
    }

    return (
        <Tween
            from={{opacity:0}}
            to={{opacity:1}}
        >
            <nav className='flex w-full p-6 justify-between align-baseline absolute top-0 left-0 z-40'>
                <button id="collective" onClick={handleBackClick}>
                    <CloseIcon />
                </button>
                <button onClick={handleShareClick} id="share" className="share" data-share-title="Autolysis" data-share-url="https://autolysis.playreplay.ca">
                <Tween to={tweenprops}>
                    <svg className={iclass} width="31" height="31" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="up" d="M16 1.99976L16 22.9998M16 1.99976L23 8.99988M16 1.99976L9 8.99988" stroke="white" strokeWidth="1.25"  strokeOpacity="1" /><path d="M25.5 7.49975L29 7.49975L29 28.4998L3 28.4998L3 7.49975L6.49999 7.49975" stroke="white" strokeWidth="1.25"  strokeOpacity="1" />
                    </svg>
                </Tween>
                </button>
                <button id="fullscreen-toggle" onClick={openFullscreen}>
                    <FullScreenIcon />
                </button>
                <div className={sclass}>
                    <div className="share-wrap">
                        <a id="fb" rel="noopener noreferrer" target="_blank" className="social fb"
                        href="https://www.facebook.com/sharer/sharer.php?u=https://autolysis.playreplay.ca"
                        data-size="large" data-share-title="Autolysis" data-share-url="https://autolysis.playreplay.ca">
                            <Tween
                                from={showDefaultShare?{opacity:0, duration: 0.5, x:15}:{opacity:seen, duration: 0.5, x:0}}
                                to={showDefaultShare?{opacity:1, duration: 0.5, x:0}:{opacity:0, duration: 0.5, x:15}}
                            >
                                <FacebookIcon />
                            </Tween>                              
                        </a>
                        <a id="tw" rel="noopener noreferrer" target="_blank" className="social tw"
                        href="https://twitter.com/intent/tweet?text=https://autolysis.playreplay.ca"
                        data-size="large" data-share-title="Autolysis" data-share-url="https://autolysis.playreplay.ca">
                             <Tween
                                from={showDefaultShare?{opacity:0, duration: 0.5, x:-15}:{opacity:seen, duration: 0.5, x:0}}
                                to={showDefaultShare?{opacity:1, duration: 0.5, x:0}:{opacity:0, duration: 0.5, x:-15}}
                            >
                                <TwitterIcon />
                            </Tween> 
                        </a>
                    </div>
                </div>
            </nav>
        </Tween>
        
    );
}

import React from 'react';
import { useEffect } from 'react';
import Nav from './components/Nav';
import MainProject from './components/MainProject';

import './App.css';

function App() {
  // This will run one time after the component mounts
  useEffect(() => {
    
    const onPageLoad = () => {
      console.log('page is loaded');
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
    console.log('useEffect');
  }, []);

  return (
    <div className="App">
      <Nav />
      <MainProject /> 
    </div>
     
  );
}

export default App;
